// Import our CSS
import styles from '../css/app.pcss';
var $ = require('jquery');

// App main
const main = async () => {
    await import('lazysizes/plugins/bgset/ls.bgset');
};

// Execute async function
main().then( () => {
    // Helpers
    var parseHTML = function(str) {
        const tmp = document.implementation.createHTMLDocument('tmp');
        tmp.body.innerHTML = str;
        return tmp.body.children;
    };

    var _si = function(selector, context = document, returnNull = false) {
        const tmp = context.querySelector(selector);
        return tmp ? tmp : returnNull ? null : _c('div');
    };
    var _s = function(selector, context = document) {
        return Array.apply(null, context.querySelectorAll(selector));
    };

    var _c = function(elm) {
        return document.createElement(elm);
    };

    var _ael = function(sel, ev, callback) {
        document.addEventListener(ev, e => {
            if (e.target === document) {
                return;
            }
            const that = e.target.closest(sel);
            if (that) {
                callback(that, e);
            }
        });
    };
    // END HELPERS
    const html = _si('html');
    const body = document.body;
    let imageDlUrl = "";
    window.addEventListener('load', function() {
        body.style.opacity = '1';
        body.classList.add('loaded');
    });
    $(document).on('click', '#js--menu-open', function(e) {
        $('body').toggleClass('nav-open');
    });
    $(document).on('click', '#to-top', function(e) {
        $(window).scrollTop(0);
    });
});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}
